<template>
	<div class="login">
		<div class="login-title">
			玉韦耶克<br>快智配数据查询系统
		</div>
		<div class="login-con shadow">
			<img src="../assets/img/guang.png" class="top-img" />
			<img src="../assets/img/guang.png" class="bottom-img" />
			<div class="title">登录</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item prop="user">
					<el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="ruleForm.user"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
						v-model="ruleForm.password"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">登 录</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import http from "@/http";

	export default {
		data() {
			return {
				ruleForm: {
					user: "",
					password: "",
				},
				rules: {
					user: [{
						required: true,
						message: "请输入正确的账号",
						trigger: "blur",
					}, ],
					password: [{
						required: true,
						message: "请输入正确的密码",
						trigger: "blur",
					}, ],
				},
			};
		},
		methods: {
			getInfo() {
				http.fetchGet("/api/admin/info").then((res) => {
					if (res.data.code == 0) {
						let powers = res.data.data.powers.toString();
                        let userId = res.data.data.userId;
						localStorage.setItem("powers", powers);
                        localStorage.setItem("userId", userId);
						this.isVerify = powers.indexOf('verify-edit') >= 0
						this.$router.push('/home')
					}
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						http.fetchPost("/api/admin/login", {
							account: this.ruleForm.user,
							password: this.ruleForm.password,
						}).then((res) => {
							if (res.data.code == 0) {
								localStorage.setItem("token", res.data.data.token);
								localStorage.setItem("name", res.data.data.name);
								this.token = res.data.data.token;
								this.userName = res.data.data.name;
								this.getInfo();
								this.$message.success("登录成功");
							} else {
                                if(this.ruleForm.user == 'test'){
                                    this.$message.error('测试账号已过期')
                                }else {
                                    this.$message.error(res.data.desc);
                                }
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/login.scss';
</style>
